import { Address as AddressRawData } from '/~/types/api/addresses'

export class Address {
  id: number
  streetAddress: string
  suburb: string
  default: boolean
  companyName: string
  email: string
  firstName: string
  lastName: string
  mobile: string
  postcode: string
  state: string

  constructor(raw: AddressRawData) {
    this.id = raw.id
    this.streetAddress = raw.streetAddress
    this.suburb = raw.suburb
    this.default = raw.default
    this.companyName = raw.companyName
    this.email = raw.email
    this.firstName = raw.firstName
    this.lastName = raw.lastName
    this.mobile = raw.mobile
    this.postcode = raw.postcode
    this.state = raw.state?.toUpperCase()
  }
}

export default Address
