import Vue, { computed, ref } from 'vue'
import type { ApiResponseData } from '/~/types/api'
import api, { jwt } from '/~/core/api'
import { ThirdPartyUserJwtStrategy } from '/~/core/Jwt/JwtStrategy/ThirdPartyUserJwtStrategy'
import { ThirdPartyOwner } from '/~/extensions/third-party-login/core/ThirdPartyOwner'
import { useExtensions } from '/~/composables/extensions'
import { ThirdPartyUserStorage } from '/~/composables/third-party-users/core/ThirdPartyUserStorage'
import { UserMetaDataKey, useUser } from '/~/composables/user'
import router from '/~/router'

const thirdPartyLoginConfig = computed<ThirdPartyLogin.Config>(() => {
  return (
    eonx.modules.find((module) => module.moduleName === 'third-party-login')
      ?.config ?? {}
  )
})
const isThirdPartyLoginEnabled = computed(
  () => thirdPartyLoginConfig.value.enabled ?? false
)
const isThirdPartyEngagementAgreementAccepted = computed(() => {
  const { userMetaData } = useUser()

  return Boolean(
    userMetaData.value[UserMetaDataKey.thirdPartyUsersEngagementAgreement]
      ?.value
  )
})

const thirdPartyOwners = ref<ThirdPartyLogin.Owner.Raw[]>([])
const thirdPartyOwnersPromise = ref<Promise<void> | null>(null)
const thirdPartyInitPromise = ref<Promise<void> | null>(null)

const isThirdPartyLoginRequired = computed(() => {
  return isThirdPartyLoginEnabled.value && thirdPartyOwners.value.length > 0
})
const isThirdPartyAgreementPending = computed(
  () =>
    isThirdPartyLoginRequired.value &&
    !isThirdPartyEngagementAgreementAccepted.value
)

async function fetchThirdPartyOwners() {
  if (thirdPartyOwnersPromise.value) {
    return thirdPartyOwnersPromise.value
  }

  thirdPartyOwners.value = []

  thirdPartyOwnersPromise.value = (async () => {
    try {
      const { data } = await api.get<
        ApiResponseData<ThirdPartyLogin.Owner.ListResponse>
      >('/v3/third-party-users/owners')

      thirdPartyOwners.value = (data?.items ?? []).map((item) => {
        return new ThirdPartyOwner(item)
      })
    } finally {
      thirdPartyOwnersPromise.value = null
    }
  })()

  return thirdPartyOwnersPromise.value
}

async function initThirdPartyLogin() {
  if (thirdPartyInitPromise.value) {
    return thirdPartyInitPromise.value
  }

  const { initExtension } = useExtensions()

  try {
    await initExtension('third-party-login')
  } catch {
    // third-party module is not installed
    return
  }

  const { user } = useUser()

  if (!isThirdPartyLoginEnabled.value || !user.value.authorized) {
    return
  }

  thirdPartyInitPromise.value = (async () => {
    try {
      const defaultStrategyJwtToken = jwt.token ?? ''

      if (user.value.authorized) {
        await fetchThirdPartyOwners()
      }

      if (isThirdPartyAgreementPending.value) {
        await router.push({ name: 'third-party-agreement' })

        return
      } else if (thirdPartyOwners.value.length > 0) {
        await fetchThirdPartyOwnerAccessToken(
          defaultStrategyJwtToken,
          thirdPartyOwners.value[0].owner?.externalId ?? ''
        )
      }
    } catch (error) {
      Vue.notify({
        type: 'error',
        text: 'Failed to login as an authorised user',
      })
    } finally {
      thirdPartyInitPromise.value = null
    }
  })()

  return thirdPartyInitPromise.value
}

async function acceptThirdPartyEngagementAgreement() {
  const { updateUserMetadata } = useUser()

  return updateUserMetadata(
    UserMetaDataKey.thirdPartyUsersEngagementAgreement,
    true
  )
}

async function fetchThirdPartyOwnerAccessToken(
  defaultStrategyJwtToken: string,
  ownerExternalId: string
) {
  const { user } = useUser()

  new ThirdPartyUserStorage().put(user.value)
  jwt.setJwtStrategy(
    new ThirdPartyUserJwtStrategy(defaultStrategyJwtToken, ownerExternalId)
  )
  await jwt.refetch()
  // TODO: replace with router navigation when find out how to correctly login third party
  window.location.assign('/')

  return new Promise((_, reject) => {
    setTimeout(() => {
      reject(new Error('Failed to redirect. Please refresh the page manually.'))
    }, 30000)
  })
}

export function useThirdPartyLogin() {
  return {
    isThirdPartyLoginEnabled,
    isThirdPartyEngagementAgreementAccepted,
    thirdPartyOwners,
    isThirdPartyLoginRequired,
    isThirdPartyAgreementPending,
    fetchThirdPartyOwners,
    initThirdPartyLogin,
    acceptThirdPartyEngagementAgreement,
    fetchThirdPartyOwnerAccessToken,
  }
}
