import algoliasearch from 'algoliasearch'
import type { Algolia, AlgoliaIndex } from '/~/types/api/algolia/algolia'
import { BaseProcessor } from '/~/core/processors/base'

type AlgoliaWorkerParams = any

export class AlgoliaWorker extends BaseProcessor {
  params: any
  appId = ''
  apiKey = ''
  processing = false
  message = ''
  total = 0
  page = 0
  perPage = 0
  facets: any
  categories: object[] = []
  multiple: object[] = []
  search?: (next?: boolean) => Promise<void>

  constructor(params?: AlgoliaWorkerParams) {
    super(params)

    this.setParams(params)
    this.reset()
  }

  setParams(params: AlgoliaWorkerParams) {
    this.params = params || {}

    this.appId = this.params.algolia_app_id || eonx.keys.algolia.appId
    this.apiKey = this.params.algolia_search_key || eonx.keys.algolia.searchKey
  }

  reset() {
    this.processing = true
    this.hits = []
    this.facets = {}
    this.categories = []
    this.page = 0
    this.message = ''
    this.total = 0
    this.perPage = 1
    this.multiple = []
  }

  get isSet() {
    return Boolean(this.appId && this.apiKey)
  }

  /*
    {
      index: 'string',
      search: {
        https://www.algolia.com/doc/api-reference/api-methods/search/
      },
    }
  */
  async getData(params: any = {}, storedData: any = undefined) {
    this.page = 0

    const mapping = params.mapping || this.params?.mapping
    const map = (collection: any) => {
      if (mapping instanceof Function) {
        return collection.map(mapping)
      }
      return collection
    }

    /*
     * Try to create default index in constructor
     */
    const index = this.createIndex(params.index || this.params.index)
    const search = async (next?: boolean) => {
      if (this.page === 0 && !storedData) {
        this.processing = true
      }

      if (storedData && !next) {
        this.hits = storedData.hits
        this.facets = storedData.facets
        this.total = storedData.total
        this.page = storedData.page
      } else {
        const [error, response] = await this.query(index, {
          page: this.page,
          ...(params.search || this.params.search),
        })

        if (error) {
          this.message = error.message ?? 'Failed to load data'
        } else {
          if (this.page === 0) {
            this.hits = map(response.hits)
          } else {
            if (this.search === search) {
              this.hits = this.hits.concat(map(response.hits || []))
            }
          }

          this.perPage = response.hitsPerPage
          this.total = response.nbHits
          this.facets = response.facets
        }
      }

      this.loaded = true
      this.processing = false
    }

    this.search = search

    return search()
  }

  async getDataMultiple(params: any, storedData?: any) {
    this.page = 0

    const map = (collection: any) => {
      if (params.mapping instanceof Function) {
        return collection.map(params.mapping)
      }
      return collection
    }

    const client = this.createClient()
    const search = async (next?: boolean) => {
      if (this.page === 0 && !storedData) {
        this.processing = true
      }

      if (storedData && !next) {
        this.hits = storedData.hits
        this.facets = storedData.facets
        this.total = storedData.total
        this.page = storedData.page
        this.multiple = storedData.multiple
      } else {
        if (params.multiple.length > 0) {
          params.multiple[0].params.page = this.page
        }

        const [error, response] = await this.multipleQueries(
          client,
          params.multiple
        )

        if (error) {
          this.message = error.message ?? 'Failed to load data'
        } else {
          const { results } = response
          const [main, ...rest] = results

          if (main) {
            if (this.page === 0) {
              this.hits = map(main.hits)
            } else {
              this.hits = this.hits.concat(map(main.hits))
            }

            this.perPage = main.hitsPerPage
            this.total = main.nbHits
            this.facets = main.facets
          }

          this.multiple = rest
        }
      }

      this.loaded = true
      this.processing = false
    }

    this.search = search

    return search()
  }

  /*
   * Helper to create an independent query to algolia with current instance parameters
   */
  async getDataOnce(params: any) {
    this.processing = true

    const index = this.createIndex(params.index)
    const [error, response] = await this.query(index, params.search)

    if (error) {
      throw error
    }

    this.processing = false
    return response || []
  }

  async getDataByID(params: any) {
    const index = this.createIndex(params.index)

    return index.getObject(params.id)
  }

  createClient() {
    return algoliasearch(this.appId, this.apiKey)
  }

  createIndex(index: string) {
    const client = this.createClient()

    return client.initIndex(index)
  }

  async next() {
    if (!this.search) {
      return
    }

    this.page += 1
    const response = await this.search()

    return response
  }

  /*
   * Send request to algolia
   */
  query(index: any, params: any) {
    const { query = '', ...rest } = params

    return index
      .search(query, rest)
      .then((content: any) => [null, content])
      .catch((error: any) => [error])
  }

  multipleQueries(client: any, params: any) {
    return client
      .multipleQueries(params)
      .then((content: any) => [null, content])
      .catch((error: any) => [error])
  }

  parseFacets(data: any) {
    const facets = data.facets ?? {}
    const result = Object.keys(facets).map((facetKey) => {
      let total = 0
      const facetValues = facets[facetKey]
      const values = Object.keys(facetValues)
        .map((facetValueKey) => {
          const count = facetValues[facetValueKey]

          total += count

          return {
            id: this.encodePath(facetValueKey),
            label: facetValueKey,
            count,
          }
        })
        .sort((a, b) => a.label.localeCompare(b.label))

      return {
        key: facetKey,
        total,
        values,
      }
    }) as any

    result.get = function (key: string) {
      return this.find((i: any) => i.key === key)
    }

    return result
  }

  async getFacets(params: any) {
    this.processing = true

    const index = this.createIndex(params.index)
    const [error, response] = await this.query(index, {
      query: '',
      page: 1,
      attributes: [],
      hitsPerPage: 1,
      ...params.search,
    })

    this.processing = false

    if (error) {
      this.message = error.message ?? 'Failed to load facets'
      return null
    } else {
      return this.parseFacets(response)
    }
  }

  /*
   * Get categories for navigation menu
   * DEPRECATED: use getFacets instead
   */
  async getCategories(params: any) {
    const index = this.createIndex(params.index)
    const [error, response] = await this.query(index, {
      query: '',
      page: 1,
      attributes: [],
      hitsPerPage: 1,
      ...params.search,
    })

    if (error) {
      this.message = error.message ?? 'Failed to load filters'
      return null
    } else {
      const categoryKey = params?.search?.facets?.[0] ?? ''
      const facets = response?.facets?.[categoryKey] ?? {}
      const categories = []

      for (const key in facets) {
        categories.push({
          id: this.encodePath(key),
          label: key,
          count: facets[key],
        })
      }

      categories.sort((a, b) => {
        return a.label.localeCompare(b.label)
      })

      if (params.firstLabel) {
        categories.unshift({
          id: null,
          label: params.firstLabel,
          count: response.nbHits,
        })
      }

      return categories
    }
  }

  /*
   * Get Stats for filters
   */

  async getStats(params: any) {
    const index = this.createIndex(params.index)

    const [error, response] = await this.query(index, {
      query: '',
      page: 1,
      attributes: [],
      hitsPerPage: 1,
      ...params.search,
    })

    if (error) {
      this.message = error.message ?? 'Failed to load price range'
      return null
    } else {
      const key = params?.search?.facets?.[0] ?? ''
      const facetsStats = response?.facets_stats ?? {}

      return facetsStats[key] || {}
    }
  }

  decodePath(str: string | null) {
    return (str || '')
      .split('-')
      .join(' ')
      .replace(/\b\w/g, (l) => l.toUpperCase())
  }

  encodePath(str: string) {
    return (str || '').split(' ').join('-').toLowerCase()
  }
}

export function getDefaultIndex(algoliaConfig: Partial<Algolia> | null) {
  if (!algoliaConfig) {
    console.error('algolia config required')
    return
  }

  const { indexes = [] } = algoliaConfig

  return (
    getIndexByFlag(algoliaConfig, 'default') ||
    indexes[0]?.index_id ||
    indexes[0]?.name
  )
}

export function getIndexByFlag(
  algoliaConfig: Partial<Algolia>,
  flag: keyof AlgoliaIndex
) {
  if (!algoliaConfig) return console.error('algolia config required')

  const { indexes, index } = algoliaConfig
  let targetIndex = index

  if (indexes instanceof Array && indexes.length > 0) {
    const indexByFlag = indexes.find((index) => index[flag]) ?? null

    targetIndex = indexByFlag?.index_id || indexByFlag?.name
  }

  return targetIndex
}

export function processAlgoliaConfig<T extends { indexes?: AlgoliaIndex[] }>(
  config: T
) {
  // if there are "new: true" indexes in the indexes list then use just them,
  // otherwise fallback to the default behavior

  config.indexes = config.indexes ?? []

  const hasNewIndexes = config.indexes.some((index) => index.new)

  if (hasNewIndexes) {
    config.indexes = config.indexes.filter((index) => index.new)
  }

  return config
}
