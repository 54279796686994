import { ref, computed } from 'vue'
import type {
  ApiResponseData,
  ApiResponseList,
  StatementAccountRawData,
} from '/~/types/api'
import api from '/~/core/api'
import { getObjectHash } from '/~/utils/object'
import { useExtensions } from '/~/composables/extensions'
import { useLogger } from '/~/composables/logger'
import { StatementAccount } from '/~/composables/statements/core/StatementAccount'
import { StatementAutoPayment } from '/~/composables/statements/core/StatementAutoPayment'
import { useStatementAutoPayments } from '/~/composables/statements/use-statement-auto-payments'
import { useStatements } from '/~/composables/statements/use-statements'

interface Config {
  statementAccounts?: {
    enabled: boolean
    allowMultiple?: boolean
  }
  securitisation?: {
    disclaimers?: {
      enabled: boolean
    }
  }
}

interface StatementAccountCreatePayload {
  name: string
  number: string
  userAddressId: number
}

interface StatementAccountUpdatePayload {
  name: string
  userAddressId: number
}

interface FetchStatementAccountOptions {
  includeAutoPayments?: boolean
  includeNextDueStatementOrders?: boolean
  limit?: number
}

const logger = useLogger('useStatementAccounts')

const { fetchActiveStatements, activeItems: activeStatementOrders } =
  useStatements()

const { isAutoPayEnabled, statementAutoPayments, fetchStatementAutoPayments } =
  useStatementAutoPayments()

const { getConfigByName } = useExtensions()

const STATEMENT_MODULE_NAME = 'statement-order'

const config = computed<Config | undefined>(() =>
  getConfigByName(STATEMENT_MODULE_NAME)
)

const allowMultipleStatementAccounts = computed<boolean>(() => {
  return config.value?.statementAccounts?.allowMultiple ?? true
})
const isStatementAccountsEnabled = computed<boolean>(() => {
  return config.value?.statementAccounts?.enabled ?? false
})
const isSecuritisationDisclaimersEnabled = computed<boolean>(() => {
  return config.value?.securitisation?.disclaimers?.enabled ?? false
})
const isStatementAccountsLoading = ref<boolean>(false)
const statementAccounts = ref<StatementAccount[]>([])
const isStatementAccountsEmpty = computed(
  () => statementAccounts.value.length === 0
)

const primaryStatementAccount = computed(() =>
  statementAccounts.value.find((statementAccount) => statementAccount.isPrimary)
)
const childStatementAccounts = computed(() =>
  statementAccounts.value.filter(
    (statementAccount) => !statementAccount.isPrimary
  )
)

async function createStatementAccount(payload: StatementAccountCreatePayload) {
  isStatementAccountsLoading.value = true

  try {
    await api.post('/v3/statement-accounts', payload)
  } finally {
    isStatementAccountsLoading.value = false
  }
}

async function updateStatementAccount(
  id: string,
  payload: StatementAccountUpdatePayload
) {
  const { data: account } = await api.put<
    ApiResponseData<StatementAccountRawData>
  >(`/v3/statement-accounts/${id}`, payload)

  if (account) {
    const statementAccountToUpdate = statementAccounts.value.find(
      (statementAccount) => statementAccount.id === account.id
    )

    if (statementAccountToUpdate) {
      Object.assign(statementAccountToUpdate, account)
    }
  }
}

async function deleteStatementAccount(id: string) {
  isStatementAccountsLoading.value = true

  try {
    await api.delete(`/v3/statement-accounts/${id}`)
  } finally {
    isStatementAccountsLoading.value = false
  }
}

async function updateStatementAccountAutoPayment(
  statementAccountId?: string,
  autoPayment: StatementAutoPayment | null = null
) {
  const matchingStatementAccount = statementAccounts.value.find(
    (statementAccount) => statementAccount.id === statementAccountId
  )

  if (!matchingStatementAccount) return

  if (autoPayment) {
    matchingStatementAccount.autoPayment = {
      ...autoPayment,
      statementAccountId: matchingStatementAccount.id,
      statementAccountNumber: matchingStatementAccount.number,
    } as StatementAutoPayment
    logger.debug('updateStatementAccountAutoPayment', {
      matchingStatementAccount,
      autoPayment,
    })
  } else {
    matchingStatementAccount.autoPayment = new StatementAutoPayment({
      statementAccountId: matchingStatementAccount.id,
      statementAccountNumber: matchingStatementAccount.number,
    })
  }
}

function clearStatementAccounts() {
  statementAccounts.value.splice(0, statementAccounts.value.length)
}

function setStatementAccounts(newAccounts: StatementAccount[]) {
  statementAccounts.value.splice(
    0,
    statementAccounts.value.length,
    ...newAccounts
  )
}

const fetchStatementAccountsPromises: Record<string, Promise<any>[]> = {}

async function fetchStatementAccounts(
  fetchOptions: FetchStatementAccountOptions
) {
  const options: FetchStatementAccountOptions = {
    includeAutoPayments: false,
    includeNextDueStatementOrders: false,
    limit: 1000,
  }

  Object.assign(options, fetchOptions)

  const optionsHash = await getObjectHash(options)

  logger.debug('fetchStatementAccounts', { optionsHash })

  if (fetchStatementAccountsPromises[optionsHash]) {
    return fetchStatementAccountsPromises[optionsHash]
  }

  fetchStatementAccountsPromises[optionsHash] = []
  const fetchPromisesList = fetchStatementAccountsPromises[optionsHash]

  isStatementAccountsLoading.value = true

  clearStatementAccounts()

  try {
    const fetchAccountsPromise = api
      .get<ApiResponseList<StatementAccountRawData>>(
        `/v3/statement-accounts?perPage=${options.limit}`
      )
      .then((response) => {
        const items = (response.data?.items ?? []).map(
          (item) => new StatementAccount(item)
        )

        setStatementAccounts(items)
      })

    fetchPromisesList.push(fetchAccountsPromise)

    if (options.includeAutoPayments && isAutoPayEnabled.value) {
      fetchPromisesList.push(fetchStatementAutoPayments())
    }

    await Promise.all(fetchPromisesList)

    if (options.includeAutoPayments && isAutoPayEnabled.value) {
      logger.debug('includeAutoPayments', {
        statementAccounts: statementAccounts.value,
        statementAutoPayments: statementAutoPayments.value,
      })
      statementAccounts.value.forEach((statementAccount) => {
        const statementAutoPayment = statementAutoPayments.value.find(
          (statementAutoPayment) =>
            statementAccount.id ===
            String(statementAutoPayment.statementAccountId)
        )

        statementAccount.autoPayment =
          statementAutoPayment ??
          new StatementAutoPayment({
            statementAccountId: statementAccount.id,
            statementAccountNumber: statementAccount.number,
          })
      })
    }

    if (options.includeNextDueStatementOrders) {
      logger.debug('includeNextDueStatementOrders', {
        statementAccounts: statementAccounts.value,
        activeStatementOrders: activeStatementOrders.value,
      })
      fetchPromisesList.push(fetchActiveStatements())
      await Promise.all(fetchPromisesList)

      statementAccounts.value.forEach((statementAccount) => {
        statementAccount.statementOrders = activeStatementOrders.value.filter(
          (statementOrder) =>
            statementOrder.statementAccount.id === statementAccount.id
        )

        const statementOrder = activeStatementOrders.value.find(
          (statementOrder) => {
            return (
              statementAccount.nextDueStatementOrder.number ===
              statementOrder.id
            )
          }
        )

        if (!statementOrder) {
          return
        }

        statementAccount.nextDueStatementOrder = statementOrder

        if (options.includeAutoPayments) {
          statementAccount.autoPayment.statement = statementOrder
        }
      })
    }
  } finally {
    delete fetchStatementAccountsPromises[optionsHash]
    isStatementAccountsLoading.value = false
  }
}

export function useStatementAccounts() {
  return {
    allowMultipleStatementAccounts,
    isStatementAccountsEnabled,
    isStatementAccountsLoading,
    isSecuritisationDisclaimersEnabled,
    statementAccounts,
    primaryStatementAccount,
    childStatementAccounts,

    createStatementAccount,
    updateStatementAccount,
    deleteStatementAccount,
    fetchStatementAccounts,
    updateStatementAccountAutoPayment,
    isStatementAccountsEmpty,
  }
}
