<script>
import { nextTick } from 'vue'
import ui from '/~/core/ui'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'

export default {
  name: 'base-button-default',
  components: {
    BaseLoader,
    BaseIcon,
  },
  props: {
    type: {
      type: String,
      default: 'button',
    },
    look: {
      type: String,
      default: 'filled',
      validator: (v) =>
        !v || /filled|outlined|light-filled|link|outlined-color/.test(v),
    },
    color: {
      type: String,
      default: 'primary',
      validator: (v) =>
        !v ||
        /primary|secondary|success|warning|error|info|white|gold|none/.test(v),
    },
    size: {
      type: String,
      default: 'md',
      validator: (v) => !v || /xs|sm|md|lg|lge|xl/.test(v),
    },
    iconSize: {
      type: String,
      default: '',
      validator: (v) => !v || /xs|sm|md|lg|xl/.test(v),
    },
    icon: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    blocked: {
      type: Boolean,
      default: false,
    },
    blockedReason: {
      type: String,
      default: null,
    },
    fullWidth: {
      type: [Boolean, String],
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    border: {
      type: Boolean,
      default: true,
    },
    classes: {
      type: [String, Array],
      default: '',
    },
  },
  emits: ['click'],
  setup() {
    return {
      ui,
    }
  },
  computed: {
    isFull() {
      if (this.fullWidth === 'mobile') {
        return ui.mobile
      }
      return this.fullWidth
    },
    loaderColor() {
      return this.look === 'filled'
        ? 'var(--color-zinc-100)'
        : 'var(--color-primary)'
    },
    bindings() {
      return this.blockedReason
        ? { ...this.$attrs, 'aria-describedby': this.blockedReason }
        : this.$attrs
    },
  },
  methods: {
    click() {
      nextTick(() => {
        this.$el.blur()
      })
    },
    onKeydownEnter() {
      this.$listeners.click?.()
    },
  },
}
</script>

<template>
  <button
    ref="button"
    v-bind="bindings"
    :type="type"
    :disabled="disabled || loading"
    :aria-disabled="blocked"
    :class="[
      classes,
      'base-button',
      rounded && 'rounded',
      border && 'base-button--border',
      color ? `base-button--${color}` : 'base-button--default',
      look && `base-button--${look}`,
      size && `base-button--${size}`,
      icon && `base-button--icon-${size}`,
      {
        'base-button--full': isFull,
        'base-button--icon': icon,
        'disabled pointer-events-none': disabled || loading || blocked,
      },
    ]"
    v-on="$listeners"
    @click="click"
    @keydown.enter.prevent.stop="onKeydownEnter"
  >
    <span v-if="loading" ref="loader" class="base-button__loader">
      <slot v-if="$slots.loader" name="loader" />
      <base-loader v-else ref="the-loader" size="xs" :color="loaderColor" />
    </span>

    <span
      :class="{
        'base-button__label': true,
        'base-button__label-icon': icon || $slots.icon,
        'base-button__label--hidden': loading,
      }"
    >
      <base-icon
        v-if="icon"
        ref="icon"
        :svg="icon"
        :size="iconSize"
        class="base-button__icon"
      />
      <slot v-else-if="$slots.icon" name="icon" class="base-button__icon" />
      <slot v-else />
    </span>
  </button>
</template>

<style lang="scss">
.base-button {
  @apply relative inline-flex h-[50px] min-w-24 shrink-0 cursor-pointer select-none items-center justify-center overflow-hidden whitespace-nowrap py-0 px-6 text-center font-[inherit] text-sm font-bold no-underline;

  will-change: opacity;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &::-moz-focus-inner {
    border: 0;
  }

  &--border:before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    content: '';
    border-radius: inherit;
    transition: opacity 64ms linear, background 64ms linear;
    will-change: opacity, background;
  }

  @mixin is-touch-device {
    @media (hover: none) and (pointer: coarse) {
      @content;

      transition: none;
    }
  }

  // LOOK

  @mixin filled-variant($background) {
    color: var(--color-primary-content);

    @apply bg-light;

    &:before {
      background: $background;
    }

    &:not(.disabled) {
      &:hover:before {
        filter: brightness(0.8);
      }
      &:active:before {
        filter: brightness(0.7);
      }
      &:focus:before {
        @apply ring-2 ring-primary ring-offset-1;
      }
    }

    &.disabled {
      color: #fff;
      background: var(--color-disabled);
    }
  }

  @mixin outlined-variant($color, $border: null) {
    color: var(--color-text);
    border: 1px solid if($border, $border, var(--color-divider));
    transition: color 0.1s linear, border 0.1s linear;

    &:before {
      opacity: 0;
    }

    &:not(.disabled) {
      &:active,
      &:hover,
      &:focus {
        color: $color;
        border: 1px solid $color;
      }
    }
  }

  @mixin outlined-color-variant($color) {
    color: $color;

    &:before {
      color: $color;
      border: 2px solid $color;
    }

    &:not(.disabled) {
      &:focus {
        filter: brightness(0.9);
      }
      &:hover {
        filter: brightness(0.8);
      }
      &:active {
        filter: brightness(0.7);
      }
    }

    &.disabled {
      border-color: var(--color-divider);
    }
  }

  @mixin outlined-color-variant-primary($color, $colorHover) {
    color: $color;

    &:before {
      color: $color;
      border: 2px solid $color;
    }

    &:not(.disabled) {
      &:focus {
        filter: none;
        background-color: $colorHover;
      }
      &:hover {
        filter: none;
        background-color: $colorHover;
      }
      &:active {
        filter: none;
        background-color: $colorHover;
      }
    }

    &.disabled {
      border-color: var(--color-divider);
    }
  }

  @mixin link-variant($color) {
    color: $color;
    transition: color 0.1s linear;

    &:before {
      opacity: 0;
    }

    &:not(.disabled) {
      &:active,
      &:hover,
      &:focus {
        color: $color;
        opacity: 0.8;
      }
    }
  }

  @mixin ewallet-v2-variant($background) {
    color: var(--color-primary-content);

    @apply bg-light;

    &:before {
      background: $background;
    }

    &:not(.disabled) {
      &:active:before,
      &:hover:before,
      &:focus:before {
        opacity: 0.8;
      }
    }

    &.disabled {
      @apply bg-gray-50 text-eonx-neutral-800;
    }
  }

  // COLOR

  &--primary,
  &--secondary {
    &.base-button--light-filled {
      transition: color 0.1s linear, background 0.1s linear, border 0.1s linear;

      @include is-touch-device;

      &:before {
        top: -1px;
        left: -1px;
        box-sizing: content-box;
        border-radius: inherit;
        opacity: 0.08;

        @include is-touch-device;
      }

      &:not(.disabled) {
        &:active,
        &:hover,
        &:focus {
          &:before {
            opacity: 1;
          }
        }
      }
    }
  }

  &--primary {
    &.base-button--filled {
      @include filled-variant(var(--color-primary));
    }

    &.base-button--outlined {
      @include outlined-variant(var(--color-primary));
    }

    &.base-button--outlined-color {
      @include outlined-color-variant-primary(
        var(--color-primary),
        var(--color-primary-lightest)
      );
    }

    &.base-button--link {
      @include link-variant(var(--color-primary));
    }

    &.base-button--light-filled {
      color: var(--color-primary);
      border: 1px solid var(--color-primary);

      &:before {
        background: var(--color-primary);
        border: 1px solid var(--color-primary);
      }

      &.disabled {
        border: 1px solid var(--color-disabled);
      }

      &:not(.disabled) {
        &:active,
        &:hover,
        &:focus {
          color: var(--color-primary-content);

          &:before {
            background: var(--color-primary);
          }
        }
      }
    }

    &.base-button--ewallet-v2 {
      @include ewallet-v2-variant(var(--color-primary));
    }
  }

  &--secondary {
    &.base-button--filled {
      @include filled-variant(var(--color-secondary));
    }

    &.base-button--outlined {
      @include outlined-variant(var(--color-secondary));
    }

    &.base-button--link {
      @include link-variant(var(--color-secondary));
    }

    &.base-button--light-filled {
      color: var(--color-secondary);
      border: 1px solid var(--color-secondary);

      &:before {
        background: var(--color-secondary);
        border: 1px solid var(--color-secondary);
      }

      &:not(.disabled) {
        &:active,
        &:hover,
        &:focus {
          color: var(--color-secondary-content);

          &:before {
            background: var(--color-secondary);
          }
        }
      }
    }
  }

  &--warning {
    &.base-button--filled {
      @include filled-variant(var(--color-warning));
    }

    &.base-button--outlined {
      @include outlined-variant(var(--color-warning));
    }

    &.base-button--link {
      @include link-variant(var(--color-warning));
    }
  }

  &--success {
    &.base-button--filled {
      @include filled-variant(var(--color-success));
    }

    &.base-button--outlined {
      @include outlined-variant(var(--color-success));
    }

    &.base-button--link {
      @include link-variant(var(--color-success));
    }
  }

  &--error {
    &.base-button--filled {
      @include filled-variant(var(--color-error));
    }

    &.base-button--outlined {
      @include outlined-variant(var(--color-error));
    }

    &.base-button--outlined-color {
      @include outlined-color-variant(var(--color-error));
    }

    &.base-button--link {
      @include link-variant(var(--color-error));
    }
  }

  &--info {
    &.base-button--filled {
      @include filled-variant(var(--color-info));
    }

    &.base-button--outlined {
      @include outlined-variant(var(--color-info));
    }

    &.base-button--link {
      @include link-variant(var(--color-info));
    }
  }

  &--white {
    &.base-button--filled {
      @include filled-variant(#fff);

      color: var(--color-primary);
    }

    &.base-button--outlined {
      @include outlined-variant(#fff);
    }

    &.base-button--link {
      @include link-variant(#fff);
    }
  }

  &--gold {
    &.base-button--filled {
      @include filled-variant(#fbbf24); // amber-400
    }

    &.base-button--link {
      @include link-variant(#fbbf24); // amber-400
    }
  }

  &--none {
    &.base-button--outlined-color {
      @include outlined-color-variant(currentColor);
    }
  }

  &--default {
    &:before {
      background-color: currentColor;
      opacity: 0;
    }

    &:not(.disabled) {
      &:hover:before,
      &:active:before {
        opacity: 0.08;
      }

      &:focus:before {
        opacity: 0.14;
      }
    }
  }

  // SIZE
  &--xs {
    @apply h-[32px] rounded px-[16px] text-sm;
  }

  &--sm {
    @apply h-[40px] rounded-md px-[16px] text-base;
  }

  &--md {
    @apply h-[48px] rounded-md px-[24px] text-base;
  }

  &--lg {
    @apply h-[56px] rounded-md px-[28px] text-base;
  }

  &--lge,
  &--xl {
    @apply text-base;
  }

  &--lge {
    @apply h-[45px];
  }

  &--xl {
    @apply h-[50px] min-w-[120px] px-6;
  }

  &.disabled {
    color: #fff;
    cursor: default;
    background: var(--color-disabled);

    &:before {
      background-color: rgba(0, 0, 0, 0) !important;
      border: 1px solid var(--color-disabled);
    }
  }

  &--link {
    min-width: auto;
    height: auto;
    padding: 0;

    @apply rounded-none;

    &.disabled {
      background: none;
      border: none;
    }
  }

  &--full {
    width: 100%;
  }

  &--icon {
    @apply m-0 w-10 min-w-[auto] shrink-0 rounded-full p-0 text-inherit;

    &.disabled {
      background: none;
      border: none;
    }

    &-xs {
      @apply w-7;
    }

    &-sm {
      @apply w-[30px];
    }

    &-md {
      @apply w-[35px];
    }

    &-lg {
      @apply w-10;
    }
  }

  &__label {
    position: relative;
    z-index: 1;
    display: flex; // to center text in cases when the button is too short
    align-items: center;
    align-self: center;
    justify-content: center;
    width: 100%;
    margin: auto;
    text-align: center;
    text-decoration: none;
    -webkit-transform: translate3d(0, 0, 0);

    &-icon {
      display: flex;
      flex: 1 1 auto;
      align-items: center;
      justify-content: center;
    }

    &--hidden {
      visibility: hidden;
    }
  }

  &__icon {
    margin-right: 0;
    margin-left: 0;
  }

  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}
</style>
