import { Payee, OrderPointsTransaction } from '/~/types/api'

export interface BatchOrderTransactionRawData {
  id: string
  bsb: string
  accountNumber: string
  accountName: string
  amount: string
  payee: Payee
  reference: string
  status: 'completed' | 'pending' | 'failed'
  subStatus?: string
  pointsTransactions?: OrderPointsTransaction[]
}

export class BatchOrderTransaction {
  id = ''
  accountName = ''
  accountNumber = ''
  name = ''
  _amount = 0
  bsb = ''
  payee: Payee = {
    abn: '',
    accountName: '',
    id: '',
    name: '',
    isProviderPayee: false,
    type: '',
    logo: {
      resource: '',
      whiteBackground: false,
    },
    status: 'pending',
    metadata: null,
  }

  status = 'new'
  subStatus: string | null = null
  error = ''
  isLoading = false
  reference = ''
  page = 1

  constructor(raw: Partial<BatchOrderTransactionRawData> = {}) {
    if (!raw.payee) delete raw.payee
    Object.assign(this, raw)
  }

  get amount() {
    return this._amount
  }

  set amount(value) {
    this._amount = +value
  }

  get statusPending() {
    return this.subStatus === 'payee_pending'
  }

  get isLongReference() {
    return this.reference.length > 9
  }
}

export default BatchOrderTransaction
