import { Pagination } from '/~/types/api'

export class BatchOrderPagination {
  currentPage = 0
  totalItems = 0
  totalPages = 0
  itemsPerPage = 0
  hasNextPage = false
  hasPreviousPage = false

  setItems(pagination: Pagination) {
    this.currentPage = pagination.currentPage
    this.totalItems = pagination.totalItems
    this.totalPages = pagination.totalPages
    this.itemsPerPage = pagination.itemsPerPage
    this.hasNextPage = pagination.hasNextPage
    this.hasPreviousPage = pagination.hasPreviousPage
  }

  get nextPage() {
    return this.currentPage + 1
  }
}
