import dayjs from 'dayjs/esm'
import { ref, computed } from 'vue'
import { Route } from 'vue-router'
import { CMSPage, CMSPageId } from '/~/types/cms'
import emitter from '/~/core/emitter'
import modal from '/~/core/mdl'
import ui from '/~/core/ui'
import { useExtensions } from '/~/composables/extensions'
import { useProvider } from '/~/composables/provider'
import { useUser } from '/~/composables/user'
import default3dsTroubleshootingGuide from '/~/configurator/defaults/3ds-troubleshooting-guide.json'
import { html as defaultDentalTerms } from '/~/configurator/defaults/dentalTerms.md'
import defaultFaq from '/~/configurator/defaults/faq.json'
import defaultTerms from '/~/configurator/defaults/terms.json'
import schemaContent from '/~/configurator/schemes/app/content.json'

export type Link = {
  appearance: 'button' | 'link'
  color: 'primary' | 'secondary'
  enabled: boolean
  label: string
  look: 'filled' | 'outlined'
  params: { [x: string]: string }
  query: { [x: string]: string }
  queryString: string
  type: 'page' | 'action' | 'link' | 'video' | 'none'
  value: string
  isRestricted: boolean
}

export type Content = {
  title: string
  text: string
  contrastText: boolean
  padding: 'sm' | 'md' | 'lg'
  contentAlignmentX: 'left' | 'center' | 'right'
  contentAlignmentY: 'top' | 'middle' | 'bottom'
}

export type Image = {
  desktop?: string
  mobile?: string
  alt?: string
  ratio?: number
  link?: string
}

// NOTE: temporary fix for drawer-faq-slide
// `Invalid prop: type check failed for prop "image". Expected , String, got Object`
export type ImageOrString = Image | string

export type Tile = {
  id: string
  content: Content
  image: Image
  link?: Link
  tileLink?: Link
  buttonTextColor: string
  titleColor: string
  contentColor: string
  contentBackgroundColor: string
  backgroundColor: string
  info: Content
}

export type MenuItem = {
  id: string
  divider: boolean
  restrictedRoles: string[]
  attachedModule: string
  enabled: boolean
  label: string
  description: string
  'bl-memberships': string
  action: Link
  'mobile-action': Link
  icon: string
  children: MenuItem[]
  mobile?: boolean
  desktop?: boolean
}

type Menu = {
  name: string
  enabled: boolean
  items: MenuItem[]
}

type HowItWorks = {
  title: string
  items: {
    id: string
    title: string
    description: string
    image: Image
  }[]
}

export type ProcessingTimesItem = {
  id: string
  type: 'statement' | 'bpay' | 'bankAccount' | 'batch' | 'payroll'
  content: string
}

export type ProcessingTimes = {
  title: string
  items: ProcessingTimesItem[]
}

type Benefits = HowItWorks

type Action = {
  appearance: string
  color: string
  enabled: boolean
  label: string
  look: string
  params: Record<string, string>
  query: Record<string, string>
  queryString: string
  type: string
  value: string
}

type CmsModal = {
  enabled: boolean
  desktop?: boolean
  mobile?: boolean
  width: string
  headerHidden: boolean
  headerLabel: string
  acknowledgeText: string
  action?: Action
  buttonLabel: string
  title: string
  content: string
  dateFrom: string
  dateTo: string
  image?: Record<string, string | number>
  metadataKey: string
  page: Record<string, string>
  showOnLogin: boolean
  saveMetadataLocally: boolean
}

export type EwalletHomeShortcut = {
  label?: string
  icon?: string
  bgColor?: string
  textColor?: string
  action?: Link
}

export type EwalletHomeService = {
  label?: string
  icon?: string
  bgColor?: string
  iconColor?: string
  textColor?: string
  action?: Link
}

const cms = ref(eonx.cms)
const pages = ref<Partial<Record<CMSPageId, CMSPage>>>({})

const cmsConfig = computed(() => cms.value.config ?? {})
const cmsContent = computed(() => cms.value.content ?? {})
const cmsMenus = computed<Menu[]>(() => cms.value.menus ?? [])
const cmsModals = computed(
  () =>
    (cms.value as any).modals ?? {
      cmsModals: {},
    }
)
const cmsDesign = computed(() => cms.value.design ?? {})
const cmsRedirects = computed(() => cms.value.redirects ?? {})
const cmsEwallet = computed(() => {
  return (
    (cms.value as any).ewallet ?? {
      widgets: [],
      settings: {},
    }
  )
})
const cmsEwalletSettings = computed(() => {
  return cmsEwallet.value.settings ?? {}
})
const cmsEwalletWidgets = computed(() => {
  return cmsEwallet.value.widgets ?? []
})
const cmsHeader = computed(() => {
  return (
    (cms.value as any).header ?? {
      widgets: [],
      settings: {},
    }
  )
})
const cmsHeaderSettings = computed(() => {
  return cmsHeader.value.settings ?? {}
})
const cmsHeaderWidgets = computed(() => {
  return cmsHeader.value.widgets ?? []
})

const ewalletLabels = computed(() => {
  return {
    ewallet: cmsEwalletSettings.value.ewalletLabel ?? 'eWallet',
    ewallets: cmsEwalletSettings.value.ewalletsLabel ?? 'eWallets',
    myEwallet: cmsEwalletSettings.value.myEwalletLabel ?? 'my eWallet',
    yourEwallet: cmsEwalletSettings.value.yourEwalletLabel ?? 'your eWallet',
    ewalletCash: cmsEwalletSettings.value.ewalletCashLabel ?? 'eWallet Cash',
    ewalletCashBalance:
      cmsEwalletSettings.value.ewalletCashBalanceLabel ??
      'eWallet Cash balance',
    ewalletPoints:
      cmsEwalletSettings.value.ewalletPointsLabel ?? 'eWallet Points',
  }
})

const faqQuestions = computed(
  () => cmsContent.value.faq?.questions ?? defaultFaq
)

const threeDsFaqModalTitle = computed(
  () =>
    (cmsContent.value as any)?.['3ds-troubleshooting-guide']?.modalTitle ??
    default3dsTroubleshootingGuide.modal.title
)
const threeDsFaqModalContent = computed(
  () =>
    (cmsContent.value as any)?.['3ds-troubleshooting-guide']?.modalContent ??
    default3dsTroubleshootingGuide.modal.content
)
const threeDsFaqPageTitle = computed(
  () =>
    (cmsContent.value as any)?.['3ds-troubleshooting-guide']?.pageTitle ??
    default3dsTroubleshootingGuide.page.title
)
const threeDsFaqPageContent = computed(
  () =>
    (cmsContent.value as any)?.['3ds-troubleshooting-guide']?.pageContent ??
    default3dsTroubleshootingGuide.page.content
)

const enableDirectDebit = computed(() => {
  const defaultEnableDirectDebit = schemaContent.items.find(
    (item) => item.name === 'enable-direct-debit'
  )
  const defaultEnableDirectDebitItem = {
    enabled: defaultEnableDirectDebit?.fields[0].default,
    title: defaultEnableDirectDebit?.fields[1].default,
    content: defaultEnableDirectDebit?.fields[2].default,
  }

  return (
    (cmsContent.value as any)?.['enable-direct-debit'] ??
    defaultEnableDirectDebitItem
  )
})

const howItWorks = computed<HowItWorks>(
  () => (cmsContent.value as any)['how-it-works'] ?? {}
)

const processingTimes = computed<ProcessingTimes>(
  () => (cmsContent.value as any)['processing-times'] || []
)

const connect = computed(() => (cmsContent.value as any)['connect'] ?? {})
const benefits = computed<Benefits>(() => cmsContent.value.benefits ?? {})
const terms = computed(() => {
  const terms = cmsContent.value.terms?.content ?? defaultTerms.content

  return /paymentsv3|dentalwallet|dentalwallet-2/.test(eonx.provider.subdomain)
    ? defaultDentalTerms
    : terms
})
const pointProgramsFees = computed(
  () => (cmsContent.value as any)['points-program-fees']?.programs ?? []
)
const isPointProgramsFees = computed(
  () => (cmsContent.value as any)['points-program-fees']?.enabled ?? true
)
const isDarkThemeForEwallet = computed(
  () => cmsDesign.value.eWalletCoverTheme === 'dark'
)
const headerLogoAction = computed(
  () => cmsDesign.value.headerLogoAction || { type: 'page', value: 'home' }
)
const headerLogoAria = computed(() => cmsDesign.value.headerLogoAria)
const headerVersion = computed(() => cmsDesign.value.headerVersion ?? 'v1')
const isHeaderVersionV2 = computed(() => headerVersion.value === 'v2')
const isHeaderVersionV3 = computed(() => headerVersion.value === 'v3')
const isHeaderVersionV4 = computed(() => headerVersion.value === 'v4')
const isHeaderVersionV7 = computed(() => headerVersion.value === 'v7')
const isHeaderVersionCustom = computed(() => headerVersion.value === 'custom')
const isHeaderHiddenOnDesktop = computed(
  () => cmsDesign.value.isHeaderHiddenOnDesktop === true
)
const isHeaderHiddenOnMobile = computed(
  () => cmsDesign.value.isHeaderHiddenOnMobile === true
)
const isHeaderHidden = computed(() => {
  return (
    (!ui.mobile && isHeaderHiddenOnDesktop.value) ||
    (ui.mobile && isHeaderHiddenOnMobile.value)
  )
})
const headerTheme = computed(() => cmsDesign.value.headerTheme ?? 'light')
const isLightThemeForHeader = computed(() => headerTheme.value === 'light')
const headerLogoMaxWidth = computed(
  () => cmsConfig.value['header-logo-max-width']
)
const leftMenuVersion = computed(() => cmsDesign.value.leftMenuVersion ?? 'v1')
const leftMenuAppearance = computed(
  () => cmsDesign.value.leftMenuAppearance ?? 'sidebar'
)
const isLeftMenuAppearanceSidebar = computed(
  () => leftMenuAppearance.value === 'sidebar'
)
const isLeftMenuAppearanceSubheader = computed(
  () => leftMenuAppearance.value === 'subheader'
)
const isLeftMenuAppearanceHidden = computed(
  () => leftMenuAppearance.value === 'hidden'
)
const footerVersion = computed(() => cmsDesign.value.footerVersion ?? 'v1')
const isFooterVersionV1 = computed(() => footerVersion.value === 'v1')
const isFooterVersionV2 = computed(() => footerVersion.value === 'v2')
const isFooterVersionV3 = computed(() => footerVersion.value === 'v3')
const footerTheme = computed(() => cmsDesign.value.footerTheme ?? 'dark')
const isDarkThemeForFooter = computed(() => footerTheme.value === 'dark')
const isLightThemeForFooter = computed(() => footerTheme.value === 'light')
const tierBadgeAction = computed(() => cmsDesign.value.tierBadgeAction ?? null)
const showTierBadge = computed(() => cmsDesign.value.showTierBadge ?? true)
const showCashbackBalance = computed(
  () => cmsDesign.value.showCashbackBalance ?? true
)
const showSavedToDate = computed(() => cmsDesign.value.showSavedToDate ?? true)
const editProfileEmailDisclaimer = computed(() => {
  return (cmsContent.value as any)?.editProfile?.emailDisclaimer ?? ''
})

const primaryColor = computed(() => cmsDesign.value['color-primary'])

const helpTitle = computed(
  () => (cmsContent.value as any)['contact-us']?.['help-title']
)
const helpDescription = computed(
  () => (cmsContent.value as any)['contact-us']?.['help-description']
)
const helpMessageMaxLength = computed(
  () => (cmsContent.value as any)['contact-us']?.['help-message-max-length']
)
const helpButtonTitle = computed(
  () => (cmsContent.value as any)['contact-us']?.['help-button-title']
)
const helpFooterNote = computed(
  () => (cmsContent.value as any)['contact-us']?.['help-footer-note']
)
const customerServiceText = computed(
  () => (cmsContent.value as any)['contact-us']?.['customer-service-text']
)
const registerPageTitle = computed(
  () => (cmsConfig.value as any)['register-page-title'] || 'Register'
)
const providerAccountDetailsDescription = computed(
  () => (cmsConfig.value as any)['provider-account-details-description']
)
const providerAccountDetailsImage = computed(() =>
  getCurrentImage((cmsConfig.value as any)['provider-account-details-image'])
)
const showPointsToBurn = computed(
  () => cmsConfig.value['show-points-to-burn'] ?? true
)

const padAgreementEnabled = computed(() => cmsContent.value.pad?.padEnabled)
const padAgreementContent = computed(() => cmsContent.value.pad?.content)

function airlinePartnersTerms(type: string) {
  return (
    (cmsContent.value as any)['airline-partners']?.['partners']?.find(
      (item: any) => item.type === type
    ) || {}
  )
}

function isRouteBlacklisted(name: string) {
  const { user } = useUser()

  return (
    cmsMenus.value.some((menu) => {
      return menu.items.some((item) => {
        return checkRoute(name, item, (user.value as any).membershipId)
      })
    }) ?? false
  )
}

function filterMenuItems({
  enabled,
  desktop,
  mobile,
  attachedModule,
  ['bl-memberships']: blMemberships,
}: MenuItem) {
  const { user } = useUser()
  const { getConfigByName } = useExtensions()

  return (
    showDesktopMobile({ desktop, mobile }) &&
    enabled &&
    (attachedModule ? getConfigByName(attachedModule) : true) &&
    // check if the menu item has black listed memberships
    (blMemberships
      ?.split(',')
      .every((m) => m.trim() !== user.value.membershipId) ??
      true)
  )
}

function mapMenuItem(item: MenuItem): MenuItem {
  const { user } = useUser()
  const { accessRestrictionEnabledRolesList } = useProvider()

  const restrictedRolesList = (
    accessRestrictionEnabledRolesList.value ?? []
  ).filter((role) => {
    return user.value.rolesList.some((userRole) => userRole === role)
  })

  const isRestricted = (item.restrictedRoles ?? []).some((role) => {
    return restrictedRolesList.some(
      (restrictedRole: string) => restrictedRole === role
    )
  })

  if (item.action) {
    item.action.isRestricted = isRestricted
  }
  if (item['mobile-action']) {
    item['mobile-action'].isRestricted = isRestricted
  }

  return {
    ...item,
    children: (item.children || []).filter(filterMenuItems).map(mapMenuItem),
  }
}

function getMenuItems(name: string) {
  const foundMenu = cmsMenus.value.find((i) => i.name === name)
  const isEnabled =
    typeof foundMenu?.enabled === 'boolean' ? foundMenu.enabled : true

  if (foundMenu && isEnabled) {
    return (foundMenu.items || []).filter(filterMenuItems).map(mapMenuItem)
  }

  return []
}

function checkRoute(
  name: string,
  item: MenuItem,
  membershipID: string | undefined
) {
  if (item.children && item.children.length) {
    const isChildDisabled = item.children.some((childItem) => {
      return checkRoute(name, childItem, membershipID)
    })

    if (isChildDisabled) return true
  }

  const { user } = useUser()
  const { accessRestrictionEnabledRolesList } = useProvider()

  const restrictedRolesList = (
    accessRestrictionEnabledRolesList.value ?? []
  ).filter((role) => {
    return user.value.rolesList.some((userRole) => userRole === role)
  })

  if (item.action?.type === 'page') {
    // match the route
    if (name === item.action.value) {
      //  return if route is not active
      // if (!item.enabled) return true
      // check for black list
      const hasBlacklistedMemberships = item['bl-memberships']
        ?.split(',')
        .some((m) => m.trim() === membershipID)
      const isRestrictedByRole = (item.restrictedRoles ?? []).some((role) => {
        return restrictedRolesList.some(
          (restrictedRole: string) => restrictedRole === role
        )
      })

      return hasBlacklistedMemberships || isRestrictedByRole
    }
  }
  return false
}

async function getPage(page: CMSPageId) {
  try {
    const response = await cms.value.getEntity(`pages/${page}`)

    pages.value = {
      ...pages.value,
      [page]: response,
    }

    emitter.emit('page-loaded', {
      name: page,
      content: response,
    })
  } catch (error: any) {
    console.debug('getPage error', error)
  }
}

async function updateVariables() {
  const root = document.documentElement
  const design = cmsDesign.value || {}

  Object.keys(design).forEach((key) => {
    if (
      key.startsWith('color-') ||
      key.startsWith('font-') ||
      key === 'border-radius'
    ) {
      root.style.setProperty(`--${key}`, design[key])
    }
  })
}

function getCurrentImage(item: any) {
  if (!item) {
    return { image: '', alt: '' }
  }
  let image

  if (ui.tablet && item.tablet?.length > 0) {
    image = item.tablet || item.desktop
  } else if (ui.mobile && item.mobile?.length > 0) {
    image = item.mobile || item.tablet || item.desktop
  } else {
    image = item.desktop
  }

  return {
    image,
    alt: item.alt,
  }
}

function getMenuItemByAttachedModule(menuName: string, moduleName: string) {
  return cmsMenus.value
    .find((menu) => menu.name === menuName)
    ?.items.find((item) => item.attachedModule === moduleName)
}

function canShowCmsModal(cmsModal: CmsModal) {
  const { userMetaData, userMetaDataLoaded } = useUser()
  let dontShowAgainFlag =
    userMetaData.value[cmsModal.metadataKey]?.value ?? false

  // TODO: remove after resetting all 'christmas' metadata keys
  if (
    cmsModal.metadataKey === 'christmas' &&
    typeof dontShowAgainFlag !== 'boolean'
  ) {
    dontShowAgainFlag = false
  }

  return (
    showDesktopMobile(cmsModal) &&
    userMetaDataLoaded.value &&
    !dontShowAgainFlag &&
    cmsModal.enabled &&
    dayjs(cmsModal.dateFrom).isSameOrBefore(dayjs()) &&
    dayjs(cmsModal.dateTo).isSameOrAfter(dayjs())
  )
}

function showCmsModalsOnLogin() {
  cmsModals.value.cmsModals?.cmsModalItems?.forEach((item: CmsModal) => {
    if (canShowCmsModal(item) && item.showOnLogin) {
      showCmsModalItem(item)
    }
  })
}

function showCmsModalsForRoute(route: Route) {
  cmsModals.value.cmsModals?.cmsModalItems?.forEach((item: CmsModal) => {
    if (
      canShowCmsModal(item) &&
      item.page?.enabled &&
      item.page.value === route.name
    ) {
      showCmsModalItem(item)
    }
  })
}

function showCmsModalItem(item: CmsModal) {
  modal.show('cms-modal', {
    props: {
      width: item.width,
      headerHidden: item.headerHidden,
      headerLabel: item.headerLabel,
      title: item.title,
      content: item.content,
      image: getCurrentImage(item.image),
      acknowledgeText: item.acknowledgeText,
      buttonLabel: item.buttonLabel,
      action: item.action,
      onConfirm: (isDontShowAgain: boolean) => {
        const { updateUserMetadata, userMetaData } = useUser()

        if (isDontShowAgain) {
          updateUserMetadata(
            item.metadataKey,
            isDontShowAgain,
            item.saveMetadataLocally
          )
          userMetaData.value[item.metadataKey] = {
            value: isDontShowAgain,
          }
        }
      },
    },
  })
}

function showDesktopMobile(item: { mobile?: boolean; desktop?: boolean }) {
  if (typeof item.mobile === 'boolean' && ui.mobile) {
    return item.mobile
  } else if (typeof item.desktop === 'boolean' && !ui.mobile) {
    return item.desktop
  } else {
    return true
  }
}

export function useCms() {
  return {
    cms,
    pages,
    cmsConfig,
    cmsContent,
    cmsModals,
    cmsMenus,
    cmsDesign,
    cmsRedirects,
    cmsEwallet,
    cmsEwalletSettings,
    cmsEwalletWidgets,
    cmsHeader,
    cmsHeaderSettings,
    cmsHeaderWidgets,
    ewalletLabels,
    faqQuestions,
    threeDsFaqPageTitle,
    threeDsFaqPageContent,
    threeDsFaqModalTitle,
    threeDsFaqModalContent,
    howItWorks,
    connect,
    benefits,
    terms,
    editProfileEmailDisclaimer,
    pointProgramsFees,
    isPointProgramsFees,
    isDarkThemeForEwallet,
    isRouteBlacklisted,
    getMenuItemByAttachedModule,
    getMenuItems,
    getPage,
    updateVariables,
    headerVersion,
    isHeaderVersionV2,
    isHeaderVersionV3,
    isHeaderVersionV4,
    isHeaderVersionV7,
    isHeaderVersionCustom,
    isHeaderHiddenOnDesktop,
    isHeaderHiddenOnMobile,
    isHeaderHidden,
    headerTheme,
    isLightThemeForHeader,
    headerLogoMaxWidth,
    leftMenuVersion,
    leftMenuAppearance,
    isLeftMenuAppearanceSidebar,
    isLeftMenuAppearanceSubheader,
    isLeftMenuAppearanceHidden,
    footerVersion,
    isFooterVersionV1,
    isFooterVersionV2,
    isFooterVersionV3,
    footerTheme,
    isDarkThemeForFooter,
    isLightThemeForFooter,
    tierBadgeAction,
    showTierBadge,
    showCashbackBalance,
    showSavedToDate,
    primaryColor,
    helpTitle,
    helpDescription,
    helpMessageMaxLength,
    helpButtonTitle,
    helpFooterNote,
    customerServiceText,
    providerAccountDetailsImage,
    providerAccountDetailsDescription,
    airlinePartnersTerms,
    getCurrentImage,

    headerLogoAction,
    headerLogoAria,
    registerPageTitle,
    showPointsToBurn,

    padAgreementEnabled,
    padAgreementContent,

    showCmsModalsForRoute,
    showCmsModalsOnLogin,
    enableDirectDebit,
    processingTimes,
  }
}
