import { Route, NavigationGuardNext } from 'vue-router'
import OtpFactory from '/~/extensions/otp/composables/core/OtpFactory'
import { useCheckout } from '/~/composables/checkout'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { useCms } from '/~/composables/cms'
import { useExtensions } from '/~/composables/extensions'
import { useLogger } from '/~/composables/logger'
import { useProvider } from '/~/composables/provider'
import {
  THIRD_PARTY_USER_ROUTE_PREFIX,
  useThirdPartyUsers,
} from '/~/composables/third-party-users'
import { useUser } from '/~/composables/user'
import { checkGuestAccess } from '/~/router/check-guest-access-handler'
import {
  AUTH_2FA_ROUTES,
  AUTH_LOGIN_ROUTE,
  AUTH_REQUEST_CODE_ROUTE,
  AUTH_VERIFY_ACCOUNT_ROUTE,
  HOME_ROUTE,
  QR_SCAN_ROUTE,
} from '/~/router/router-constants'

const logger = useLogger()
const { isThirdPartyEngagementAgreementAccepted } = useThirdPartyUsers()

export function handleBlacklistedCms(
  toRoute: Route,
  next: NavigationGuardNext
) {
  const { isRouteBlacklisted } = useCms()

  if (toRoute.name && isRouteBlacklisted(toRoute.name)) {
    next({ name: HOME_ROUTE })
    return false
  }
  return true
}

// Document title setting
export function setDocumentTitle(toRoute: Route) {
  const { providerTitle } = useProvider()

  const title =
    toRoute.meta?.title ||
    (toRoute.matched || [])
      .map((item) => item.meta.title)
      .filter((item) => item)
      .pop() // fallback to parent title if none

  document.title = [title, providerTitle.value]
    .filter((item) => item)
    .join(' - ')
}

export const handleQRCodeRedirection = (
  toRoute: Route,
  next: NavigationGuardNext
) => {
  if (
    toRoute.query?.qrConnectCode &&
    toRoute.name &&
    toRoute.name !== QR_SCAN_ROUTE
  ) {
    next(`/qr-connect/scan/?qrConnectCode=${toRoute.query.qrConnectCode}`)
  }
}

export function handleUserAuthorization(
  toRoute: Route,
  next: NavigationGuardNext
) {
  const { user } = useUser()

  if (user.value?.authorized) return true

  const otp = OtpFactory.restore('login')

  if (otp.jwt?.token && !AUTH_2FA_ROUTES.includes(toRoute.name)) {
    logger.debug('User not authorized, but JWT is valid, redirecting to 2FA')

    const targetRoute = otp.otpId
      ? AUTH_VERIFY_ACCOUNT_ROUTE
      : AUTH_REQUEST_CODE_ROUTE

    next({ name: targetRoute })

    return false // Stop navigation
  }

  if (!otp.jwt?.token && AUTH_2FA_ROUTES.includes(toRoute.name)) {
    logger.debug(
      'User at 2FA route, but JWT is not valid, redirecting to login'
    )
    next({ name: AUTH_LOGIN_ROUTE })
    return false
  }

  if (checkGuestAccess(toRoute)) return true

  logger.debug('User not authorized, redirecting to login')
  next({ name: AUTH_LOGIN_ROUTE })
  return false
}

// Recroom dynamic hero height
export const setDynamicHeroHeight = () => {
  document.documentElement.style.setProperty('--hero-height-dynamic', '0px')
}

export async function checkAndInitializeExtensionReadiness(
  toRoute: Route,
  next: NavigationGuardNext,
  isExtensionReady: (routeName?: string | null) => boolean,
  createTask: (routeName?: string | null) => Promise<void>
) {
  if (isExtensionReady(toRoute.name)) {
    logger.debug(`Extension ready: ${toRoute.name}`)
    return true // Continue navigation if the extension is ready
  }

  try {
    logger.debug(`Creating task for extension: ${toRoute.name}`)
    await createTask(toRoute.name)
    if (toRoute.name) next({ name: toRoute.name }) // Redirect to the same route to recheck readiness
    return false
  } catch (error) {
    console.error(`Failed to create task for extension: ${error}`)
    next({ name: HOME_ROUTE }) // Redirect to home if task creation fails
    return false
  }
}

export async function handleExtensionTasks(
  toRoute: Route,
  next: NavigationGuardNext
) {
  const { isExtensionReady, createTask, createTaskByPath, getTaskByPath } =
    useExtensions()

  if (!toRoute.name) {
    try {
      logger.debug(`Creating task for path: ${toRoute.path}`)
      await createTaskByPath(toRoute.path)
      const done = await getTaskByPath(toRoute.path)

      if (done) {
        logger.debug(`Task done for path: ${toRoute.path}`)
        next(toRoute.fullPath)
      } else {
        logger.debug(`Task not done for path: ${toRoute.path}`)
        next({ name: HOME_ROUTE })
      }
      return false
    } catch (error) {
      console.error(`Failed to create or get task for path: ${error}`)
      next({ name: HOME_ROUTE }) // Redirect to home if task creation or retrieval fails
      return false
    }
  } else if (
    !(await checkAndInitializeExtensionReadiness(
      toRoute,
      next,
      isExtensionReady,
      createTask
    ))
  ) {
    return false
  }

  return true
}

export const handleCheckout = (to) => {
  const { currentFlowType } = useCheckout()
  const isQuickBuy = to.meta?.quickBuy

  if (!to.hash || isQuickBuy) {
    currentFlowType.value =
      to.meta?.flowType ?? (isQuickBuy ? FlowType.quickBuy : FlowType.purchase)
  }

  return true
}

export function handleCmsModals(to: Route, from: Route) {
  const { showCmsModalsForRoute } = useCms()

  if (from.name !== to.name) {
    showCmsModalsForRoute(to)
  }
}

export function handleThirdPartyUserAgreement(
  to: Route,
  next: NavigationGuardNext
) {
  if (
    !isThirdPartyEngagementAgreementAccepted.value &&
    to.hash.startsWith(THIRD_PARTY_USER_ROUTE_PREFIX) &&
    to.hash !== `${THIRD_PARTY_USER_ROUTE_PREFIX}-agreement`
  ) {
    next({
      name: to.name ?? undefined,
      hash: `${THIRD_PARTY_USER_ROUTE_PREFIX}-agreement`,
      params: {
        intendedUrl: to.fullPath,
      },
    })

    return false
  }

  return true
}
