import mitt, { Emitter as MittEmitter } from 'mitt'
import { NotificationOptions } from 'vue-notification'
import type { RawLocation } from 'vue-router'
import { Address } from '/~/types/api'
import { UserRawData } from '/~/types/api/user'
import { CMSPage, CMSPageId } from '/~/types/cms'
import type { ModalShowOptions } from '/~/core/mdl'

export type Events = {
  'page-empty': CMSPageId
  'auth:success': UserRawData
  'auth:required': undefined
  'auth:unauthenticated': undefined
  'main:env-loaded': undefined
  'cms:frame-loaded': () => void
  'modal:show': {
    name: string
  } & ModalShowOptions
  'ui:hide-all-popups': undefined
  'router:back': undefined
  'router:replace': RawLocation
  'router:push': RawLocation
  'router:after-each': {
    name?: string | null
    params: Record<string, string>
    query: Record<string, string | (string | null)[]>
    path: string
  }
  'savings-updated': undefined
  'payment-methods:created': undefined
  'payment-methods:removed': undefined
  'payment-methods:updated': undefined
  'payment-methods:verified': undefined
  'payment-methods:failed': undefined
  'ewallet:close': undefined
  'checkout-method-selected': undefined
  'page-loaded': {
    name: CMSPageId
    content?: CMSPage
  }
  'page-error': {
    name: CMSPageId
  }
  signout: undefined
  'validation:business-number': any
  'edit-profile-errors': any
  notify: string | NotificationOptions
  'cart:updated': number
  'checkout:cart-items-gone': () => void
  'notifications:points_balance_updated': undefined
  'notifications:ewallet_balance_updated': undefined
  'notifications:batch-order': {
    batchOrderId: string
    batchOrderStatus: string
    notification: any
  }
  'notifications:payment_order_status_updated': {
    orderNumber: string
    orderStatus: string
  }
  'batch-order-creation-failed': string
  'batch-order-creation-cancelled': string
  'fz.validation.error': Error
  'fz.verify.error': Error
  'fz.timeout.error': Error
  'fz.sca.success': undefined
  'fz.sca.error': Error
  'purchases-updated': undefined
  'addresses:add': Address
  'addresses:remove': Address
  'google-map:auth-failed': undefined
  'notifications:order_item_dispatched': undefined
  'activity:refresh': undefined
  'notifications:update_list': undefined
}

type EventName = keyof Events

type EventPayload<T extends EventName> = Events[T]

type Handler<T extends EventName> = (payload: EventPayload<T>) => void

type Emitter = MittEmitter<Events> & {
  once: <T extends EventName>(type: T, handler: Handler<T>) => void
}

const emitter = mitt() as Emitter

emitter.once = <T extends EventName>(type: T, handler: Handler<T>): void => {
  const fn = (payload: EventPayload<T>) => {
    emitter.off(type, fn)
    handler(payload)
  }

  emitter.on(type, fn)
}

export default emitter

export type { EventName, EventPayload, Handler }
