import { reactive } from 'vue'
import { BatchOrderLoader, BatchOrderLoaderParams } from './BatchOrderLoader'
import { BatchOrderPagination } from './BatchOrderPagination'
import { BatchOrderTransaction } from './BatchOrderTransaction'
import { BatchOrderTransactionList } from './BatchOrderTransactionList'
import { BatchOrderTransactionsTab } from './BatchOrderTransactionsTab'
import { BatchOrderTransactionsTabList } from './BatchOrderTransactionsTabList'

export class BatchOrderPayeesTable {
  transactionList: BatchOrderTransactionList
  pagination: BatchOrderPagination
  tabs: BatchOrderTransactionsTabList
  loader: BatchOrderLoader

  constructor(params: BatchOrderLoaderParams) {
    this.transactionList = reactive(new BatchOrderTransactionList())
    this.pagination = reactive(new BatchOrderPagination())
    this.tabs = reactive(new BatchOrderTransactionsTabList())
    this.loader = reactive(new BatchOrderLoader(params))
  }

  async load(page: number, add?: boolean) {
    this.pagination.currentPage = page
    const result = await this.loader.load(page)

    if (!result) {
      return
    }
    const { pagination, items } = result

    this.pagination.setItems(pagination)
    const transactionList = items.map((item) => new BatchOrderTransaction(item))

    if (add) {
      this.transactionList.addItems(transactionList)
    } else {
      this.transactionList.setItems(transactionList)
    }
  }

  async setPerPage(perPage: string) {
    this.loader.setPerPage(perPage)
    await this.load(1)
  }

  async setActiveTab(tab: BatchOrderTransactionsTab) {
    this.tabs.setActiveTab(tab)
    this.loader.setStatuses(this.tabs.activeTab.queryStatuses)
    await this.load(1)
  }

  loadNext() {
    setTimeout(() => {
      if (!this.loader.loading && this.pagination?.hasNextPage) {
        this.load(this.pagination.nextPage, true)
      }
    })
  }

  reset() {
    this.transactionList.reset()
    this.pagination.currentPage = 0
  }
}
