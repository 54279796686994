import { computed, reactive, ref } from 'vue'
import {
  StatementOrderRawData,
  ApiResponseList,
  ApiResponseData,
} from '/~/types/api'
import { StatementFilterConfig } from '/~/types/extensions'
import api from '/~/core/api'
import { useExtensions } from '/~/composables/extensions'
import { useProvider } from '/~/composables/provider'
import { Statement } from '/~/composables/statements/core/Statement'

const STATEMENTS_MODULE_NAME = 'statement-order'

const { getMetaByName, getManifestByName } = useExtensions()

const promiseActiveSatements = ref(Promise.resolve())
const loadingActiveSatements = ref(false)

const module = computed(() => getManifestByName(STATEMENTS_MODULE_NAME))
const meta = computed(() => getMetaByName(STATEMENTS_MODULE_NAME))

const isStatementsEnabled = computed(() => Boolean(module.value))

const blockedPaymentMessage = computed(() => {
  if (meta.value?.blockedPaymentMessage) {
    return meta.value.blockedPaymentMessage
  } else {
    const { customerServiceNumber, providerClientName } = useProvider()

    return `Payment is overdue. Please contact ${providerClientName.value} on ${customerServiceNumber.value} to make this payment. This overdue statement will be removed from this system on the issue of your next statement. Please note that you have missed out on the opportunity to earn points on this statement. Subject to your membership.`
  }
})

const statementsInitialState = reactive({
  items: [] as Statement[],
  fetching: false,
  total: 0,
  totalPages: 0,
  page: 1,
  hasNextPage: true,
  feesSuccess: false,
})

type StatementsState = typeof statementsInitialState

const activeStatements = reactive(Object.assign({}, statementsInitialState))
const activeNewStatements = reactive(Object.assign({}, statementsInitialState))
const inactiveStatements = reactive(Object.assign({}, statementsInitialState))

const totalInactivePages = computed(() => inactiveStatements.totalPages)

const activeItems = computed(() => activeStatements.items)
const activeNewItems = computed(() => activeNewStatements.items)
const inactiveItems = computed(() => inactiveStatements.items)

const isActiveFetching = computed(() => activeStatements.fetching)
const isActiveNewFetching = computed(() => activeNewStatements.fetching)
const isInactiveFetching = computed(() => inactiveStatements.fetching)
const isFetching = computed(
  () => isActiveFetching.value || isInactiveFetching.value
)

const activeStatementHasNextPage = computed(() => activeStatements.hasNextPage)
const isInactiveLoaded = computed(() => !inactiveStatements.hasNextPage)

const isActiveEmpty = computed(() => activeStatements.items.length === 0)
const isInactiveEmpty = computed(() => inactiveStatements.items.length === 0)
const isStatementsEmpty = computed(
  () => isActiveEmpty.value && isInactiveEmpty.value
)

const configSerialize = (config: Partial<StatementFilterConfig>) =>
  Object.entries(config)
    .map(([key, val]) => {
      // Serialize statuses param as array
      if (Array.isArray(val)) {
        return val.map((item) => `${key}[]=${item}`).join('&')
      }

      return `${key}=${val}`
    })
    .join('&')

const fetchStatements = async (
  store: StatementsState,
  config: Partial<StatementFilterConfig> = {},
  isLoadMore = false
) => {
  if (!store.fetching) {
    store.fetching = true

    config = {
      ...config,
      perPage: 1000,
    }

    const params = configSerialize(config)

    try {
      const { data } = await api.get<ApiResponseList<StatementOrderRawData>>(
        `/v3/statement-orders?${params}`
      )

      const items = isLoadMore ? store.items : []

      store.items = [
        ...items,
        ...(data.items.map((item) => new Statement(item)) ?? []),
      ]

      store.total = data?.pagination.totalItems ?? 0
      store.page = data?.pagination.currentPage ?? 1
      store.totalPages = data?.pagination.totalPages ?? 0
      store.hasNextPage = data.pagination.hasNextPage

      statementsInitialState.items = (data?.items ?? []).map(
        (item) => new Statement(item)
      )
      statementsInitialState.total = data?.pagination.totalItems ?? 0
    } catch (error) {
      console.error(error)
    } finally {
      store.fetching = false
      loadingActiveSatements.value = false
    }
  }
}

async function fetchStatementOrder(orderNumber: string) {
  const { data } = await api.get<ApiResponseData<StatementOrderRawData>>(
    `/v3/statement-orders/${orderNumber}`
  )

  return new Statement(data)
}

function fetchActiveStatements(params = {}, loadMore = false) {
  if (loadingActiveSatements.value) return promiseActiveSatements.value

  loadingActiveSatements.value = true

  promiseActiveSatements.value = fetchStatements(
    activeStatements,
    {
      ...params,
      accountStatus: ['approved'],
      active: 1,
    },
    loadMore
  )

  return promiseActiveSatements.value
}

const fetchActiveNewStatements = (params = {}) => {
  return fetchStatements(activeNewStatements, {
    ...params,
    'order[dueDate]': 'asc',
    statuses: ['new', 'scheduled', 'failed'],
    accountStatus: ['approved'],
    active: 1,
  })
}

const fetchInActiveStatements = (params = { page: 1 }) => {
  return fetchStatements(inactiveStatements, {
    ...params,
    accountStatus: ['approved'],
    active: 0,
  })
}

const loadMore = (isVisible: boolean) => {
  if (
    !isVisible &&
    isActiveFetching &&
    isInactiveFetching &&
    isInactiveLoaded
  ) {
    return
  }

  const params = {
    page: inactiveStatements.page + 1,
    active: 0,
  }

  inactiveStatements.page += 1

  fetchStatements(inactiveStatements, params, true)
}

const clearActiveStatements = () => {
  Object.assign(activeStatements, statementsInitialState)
}

const clearInActiveStatements = () => {
  Object.assign(inactiveStatements, statementsInitialState)
}

export const useStatements = () => ({
  loadingActiveSatements,
  blockedPaymentMessage,
  activeStatements,
  activeItems,
  activeNewItems,
  inactiveItems,
  totalInactivePages,
  fetchStatements,
  fetchActiveStatements,
  fetchActiveNewStatements,
  fetchInActiveStatements,
  clearActiveStatements,
  clearInActiveStatements,
  loadMore,
  isStatementsEnabled,
  isInactiveLoaded,
  isActiveFetching,
  isActiveNewFetching,
  isInactiveFetching,
  isFetching,
  isStatementsEmpty,
  isActiveEmpty,
  isInactiveEmpty,
  fetchStatementOrder,
  activeStatementHasNextPage,
})
