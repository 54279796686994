import { reactive } from 'vue'
import modal from '/~/core/mdl'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { PayIdManager } from './core/PayIdManager'
import { PayIdOrdersManager } from './core/PayIdOrdersManager'

const payIdOrdersManager = reactive(
  new PayIdOrdersManager(
    {
      url: '/v3/activities',
      category: 'bill_payments',
      status: 'awaiting_funds_pi',
    },
    {
      PaymentOrder: '/v3/payment-orders',
      BatchOrder: '/v3/batch-orders',
    }
  )
)

const payIdManager = reactive(
  new PayIdManager({
    createUrl: '/v3/payids',
  })
)

function cancelDescription(status: string, subStatus: string) {
  if (['cancelled'].includes(status)) {
    if (
      ['payee_deleted', 'payee_blacklisted', 'payee_rejected'].includes(
        subStatus
      )
    ) {
      return 'This PayID payment was cancelled because the payee is no longer available.'
    }

    return 'This PayID payment was cancelled manually'
  }

  if (['expired'].includes(status)) {
    return 'This PayID payment was cancelled because the<br/> PayID details expired.'
  }

  return null
}

function onCancelPaymentOrder(
  orderNumber: string,
  goToPage: () => void,
  flowType: FlowType
) {
  modal.show('confirm-v3', {
    props: {
      title: 'Cancel payment',
      description: `Are you sure you want to cancel this payment?
      If you proceed, the details displayed will no longer be valid, and you'll need to start the process again if you wish to make the payment later.`,
      width: 'xs',
      headerBorderBottom: true,
      showCloseIcon: false,
      cancelLabel: 'Back',
      confirmLabel: 'Cancel payment',
      onConfirm: async () => {
        const order = await payIdOrdersManager.cancel(orderNumber, flowType)

        if (order.status === 'cancelled') {
          goToPage()
        }
      },
    },
  })
}

export function usePayId() {
  return {
    cancelDescription,

    payIdOrdersManager,
    payIdManager,

    onCancelPaymentOrder,
  }
}
