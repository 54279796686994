import { ApiResponseData, ApiResponseList } from '/~/types/api'
import api from '/~/core/api'

export type LoaderParams = {
  url?: string
  [key: string]: string | string[] | undefined
}

export class Loader {
  params: LoaderParams
  loading = false
  queryString = ''

  constructor(params: LoaderParams) {
    this.params = params
    this.updateQuery()
  }

  setParam(param: string, value: string | string[] | undefined) {
    this.params[param] = value
    this.updateQuery()
  }

  updateQuery() {
    const query = new URLSearchParams()

    for (const [key, value] of Object.entries(this.params)) {
      if (key === 'url') continue
      if (Array.isArray(value)) {
        value.forEach((v) => {
          query.append(key, v)
        })
      } else if (value) {
        query.append(key, value)
      }
    }
    this.queryString = query.toString()
  }

  async load() {
    try {
      this.loading = true
      const response = await api.get<ApiResponseList<any>>(
        `${this.params.url}?${this.queryString}`
      )

      return response.data
    } catch (error) {
      console.error(error)
    } finally {
      this.loading = false
    }
  }

  async update(payload, url?) {
    try {
      this.loading = true
      const { data } = await api.put<ApiResponseData<any>>(
        url || this.params.url,
        payload
      )

      return data
    } catch (error) {
      console.error(error)
    } finally {
      this.loading = false
    }
  }
}
