import { loadStripe } from '@stripe/stripe-js/pure'
import { ref } from 'vue'
import { ApiResponseData } from '/~/types/api'
import api from '/~/core/api'
import { roundFigure } from '/~/utils/format/numeric'

const DEFAULT_ERROR_MESSAGE =
  '3D Secure verification failed. Please contact your bank.'

interface VerifyStripeOptions {
  cardId: string
  amount: number
  payeeId?: string
  multipleSources: boolean
}

interface VerifyStripeResult {
  securityToken: string
}

// TODO: this is a temporary model for the response until we finalize it
interface SecurityRequestResponse {
  clientSecret: string
  publishableKey: string
}

const verifying = ref<boolean>(false)

const createSecurityRequest = async function (
  cardId: string,
  amount: number,
  payeeId?: string
) {
  const response = await api.post<ApiResponseData<SecurityRequestResponse>>(
    `/v3/payment-methods/${cardId}/security-requests/v2/stripe`,
    {
      amount: `${roundFigure(amount)}`,
      payeeId,
    },
    {
      notify: false,
    }
  )

  return response.data
}

const confirmCardThroughStripe = async function (
  publishableKey: string,
  clientSecret: string
) {
  const stripe = await loadStripe(publishableKey)

  const confirmResponse = await stripe?.confirmCardPayment(clientSecret)

  if (confirmResponse?.error) {
    if (confirmResponse.error.message) {
      throw new Error(confirmResponse.error.message)
    } else {
      throw new Error(DEFAULT_ERROR_MESSAGE)
    }
  } else {
    if (confirmResponse?.paymentIntent?.id) {
      const securityToken = btoa(confirmResponse.paymentIntent.id)

      return securityToken
    } else {
      console.error(
        'paymentIntent.id is missing from the confirmCardPayment response'
      )
      throw new Error(DEFAULT_ERROR_MESSAGE)
    }
  }
}

const verifyCard = async function (
  options: VerifyStripeOptions
): Promise<VerifyStripeResult> {
  try {
    verifying.value = true
    const securityResponse = await createSecurityRequest(
      options.cardId,
      options.amount,
      options.payeeId
    )

    if (!securityResponse) throw new Error(DEFAULT_ERROR_MESSAGE)

    const securityToken = await confirmCardThroughStripe(
      eonx.keys.stripe,
      securityResponse.clientSecret
    )

    return { securityToken }
  } finally {
    verifying.value = false
  }
}

export const useStripe = () => ({
  verifyingCard: verifying,
  verifyCard,
})
