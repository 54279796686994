import Bugsnag from '@bugsnag/js'
import { ref } from 'vue'
import { ApiResponseData } from '/~/types/api'
import {
  FatZebraServicePayload,
  FatZebraVerifyCardPayload,
} from '/~/types/fat-zebra'
import api from '/~/core/api'
import emitter from '/~/core/emitter'
import { roundFigure } from '/~/utils/format/numeric'
import Storage from '/~/utils/localStorage'
import Address from '/~/composables/addresses/core/Address'
import { useProvider } from '/~/composables/provider'

const verifying = ref(false)
const warnNotification = ref()
const errorNotification = ref()

const EVENTS = {
  VALIDATION_ERROR: 'fz.validation.error',
  SCA_ERROR: 'fz.sca.error',
  SUCCESS: 'fz.sca.success',
  TIMEOUT_ERROR: 'fz.timeout.error',
  VERIFY_ERROR: 'fz.verify.error',
} as const

const DEFAULT_ERROR_MESSAGE =
  '3D Secure verification failed. Please contact your bank.'

function handleError(event: any) {
  const message = event.message ?? DEFAULT_ERROR_MESSAGE

  event.detail?.errors.map((error: any) => console.error(error))

  Bugsnag.notify(new Error(message), (event) => {
    event.severity = 'info'
  })

  return new Error(message)
}

function startNotifications() {
  warnNotification.value = setTimeout(() => {
    emitter.emit('notify', {
      text: "Your payment is taking a little longer than usual. Note that payment processing can take up to one minute. Please don't close or refresh the page.",
      duration: 10000,
    })
  }, 10000)

  errorNotification.value = setTimeout(() => {
    emitter.emit(
      EVENTS.TIMEOUT_ERROR,
      new Error(
        'There was a problem processing your payment. Please try again later.'
      )
    )
  }, 60000)
}

function stopNotifications() {
  clearTimeout(warnNotification.value)
  clearTimeout(errorNotification.value)
}

function handleResponse(
  resolve: (data: { securityToken: string }) => void,
  reject: (error: Error) => void
) {
  const successSubscriber = (event: any) => {
    const { data } = event.detail || {}

    // Obtain 3DS2 results which will be used to make a purchase in the backend
    if (data) {
      if (data.ver === 'N') {
        reject(
          handleError({
            message: DEFAULT_ERROR_MESSAGE,
          })
        )
      } else {
        const stringPayload = JSON.stringify(data)
        const securityToken = btoa(stringPayload)

        resolve({ securityToken })
      }
    } else {
      reject(handleError(event))
    }
    unsubscribe()
  }

  emitter.on(EVENTS.SUCCESS, successSubscriber)

  const errorSubscriber = (event: any) => {
    reject(handleError(event))
    unsubscribe()
  }

  emitter.on(EVENTS.SCA_ERROR, errorSubscriber)
  emitter.on(EVENTS.TIMEOUT_ERROR, errorSubscriber)
  emitter.on(EVENTS.VALIDATION_ERROR, errorSubscriber)
  emitter.on(EVENTS.VERIFY_ERROR, errorSubscriber)

  const unsubscribe = () => {
    stopNotifications()
    emitter.off(EVENTS.SCA_ERROR, errorSubscriber)
    emitter.off(EVENTS.TIMEOUT_ERROR, errorSubscriber)
    emitter.off(EVENTS.VALIDATION_ERROR, errorSubscriber)
    emitter.off(EVENTS.VERIFY_ERROR, errorSubscriber)
    emitter.off(EVENTS.SUCCESS, successSubscriber)
  }
}

async function loadService(payload: FatZebraServicePayload) {
  if (window.FatZebra) {
    return
  }

  const { gatewaySdkUrl } = useProvider()
  const url = gatewaySdkUrl.value

  if (!url) {
    console.warn('No SDK url has been provided')
  }

  await loadSongbird()

  return new Promise<void>((resolve, reject) => {
    const script = document.createElement('script')
    const container = document.head || document.body

    script.type = 'text/javascript'
    script.src = url
    script.onload = () => {
      try {
        if (!window.FatZebra) {
          throw new Error('No FatZebra object')
        }

        if (!window.fz) {
          const fz = new window.FatZebra(payload)

          window.fz = fz

          fz.on(EVENTS.VALIDATION_ERROR, (event: any) => {
            emitter.emit(EVENTS.VALIDATION_ERROR, event)
          })

          fz.on(EVENTS.SCA_ERROR, (event: any) => {
            emitter.emit(EVENTS.SCA_ERROR, event)
          })

          fz.on(EVENTS.SUCCESS, (event: any) => {
            emitter.emit(EVENTS.SUCCESS, event)
          })
        }

        resolve()
      } catch (error) {
        reject(error)
      }
    }
    script.onerror = reject

    container.append(script)
  })
}

async function loadSongbird() {
  try {
    await loadSongbirdMain()
    await loadSongbirdAdditional()
  } catch (error) {
    handleError(new Error('Failed to preload songbird script'))
  }
}

async function loadSongbirdMain() {
  return new Promise((resolve, reject) => {
    try {
      const script = document.createElement('script')
      const container = document.head || document.body

      script.type = 'text/javascript'
      script.async = !0
      script.src = 'https://songbird.cardinalcommerce.com/edge/v1/songbird.js'

      script.onload = resolve

      script.onerror = function () {
        console.error(`FatZebra: Failed to load script with src ${this.src}`)
        reject(new Error('FatZebra card verification failed'))
      }

      container.appendChild(script)
    } catch (error) {
      reject(error)
    }
  })
}

async function loadSongbirdAdditional() {
  return new Promise<void>((resolve, reject) => {
    try {
      const scriptsCount = 16
      let loadedScripts = 0

      const onScriptLoaded = () => {
        loadedScripts++

        if (loadedScripts === scriptsCount) {
          resolve()
        }
      }

      for (let i = 0; i < 19; i++) {
        if (i >= 6 && i <= 8) {
          // non-existing scripts
          continue
        }

        const script = document.createElement('script')
        const container = document.head || document.body

        script.type = 'text/javascript'
        script.src = `https://songbird.cardinalcommerce.com/edge/v1/597f4104d311c33d4189/${i}.597f4104d311c33d4189.songbird.js`

        script.async = true
        script.onload = onScriptLoaded
        script.onerror = onScriptLoaded

        container.appendChild(script)
      }
    } catch (error) {
      reject(error)
    }
  })
}

type VerifyCardArgs = {
  cardId: string
  amount: number
  address: Address
  payeeId?: string
}

type SecurityResponse = {
  accessToken: string
  amount: string
  createdAt: string
  creditCardToken: string
  paymentMethodId: string
  status: 'initiated'
  username: string
  verificationCode: string
  verificationReference: string
}

async function verifyCard({
  address,
  amount,
  cardId,
  payeeId,
}: VerifyCardArgs) {
  const { data: securityResponse } = await api.post<
    ApiResponseData<SecurityResponse>
  >(
    `/v3/payment-methods/${cardId}/security-requests/fat-zebra-v2`,
    {
      amount: `${roundFigure(amount)}`,
      payeeId,
    },
    {
      notify: false,
    }
  )

  if (!securityResponse) {
    throw new Error('No security response')
  }

  const isTest = eonx.env !== 'production'

  if (!window.fz) {
    startNotifications()
    await loadService({
      username: securityResponse.username,
      test: isTest,
    })
  } else {
    window.fz.fzConfig.username = securityResponse.username
    window.fz.fzConfig.test = isTest
  }

  Storage.set('fz-access-token', securityResponse.accessToken)

  if (!address) {
    throw new Error('No address')
  }

  const payload: FatZebraVerifyCardPayload = {
    customer: {
      firstName: address.firstName,
      lastName: address.lastName,
      email: address.email,
      address: address.streetAddress,
      city: address.suburb,
      postcode: address.postcode,
      state: address.state,
      country: 'AU',
    },
    paymentIntent: {
      payment: {
        amount: roundFigure(amount * 100),
        currency: 'AUD',
        reference: securityResponse.verificationReference,
      },
      verification: securityResponse.verificationCode,
    },
    paymentMethod: {
      type: 'card_on_file',
      data: {
        token: securityResponse.creditCardToken,
      },
    },
  }

  window.fz?.verifyCard(payload)
}

export const useFatZebra = () => ({
  verifyingCard: verifying,
  verifyCard: (data: VerifyCardArgs) =>
    new Promise((resolve, reject) => {
      verifying.value = true

      handleResponse(resolve, reject)

      return verifyCard(data)
        .catch(() => {
          emitter.emit(EVENTS.VERIFY_ERROR, new Error(DEFAULT_ERROR_MESSAGE))
        })
        .finally(() => {
          verifying.value = false
        })
    }),
})
